<template>
    <div class="content-main">
        <!--搜索框-->
        <div class="store-search">
            <div>
                <h3>官方认证店搜索</h3>
            </div>
            <el-form v-model="form" label-width="120px">
                <el-form-item label="区域:">
                    <el-cascader
                            size="large"
                            :options="areaOptions"
                            v-model="form.area"
                            @change="handleAreaChange"
                            class="m-full"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button class="m-full" @click="getList">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!--分页区-->
        <!--分页区-->
        <div style="padding:20px 0;width: 60%;margin: 0 auto">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
            <el-divider></el-divider>
        </div>

        <!--列表区域-->
        <div class="store-list">

            <div v-if="storeList.length == 0">
                <el-empty description="正在努力开发中，敬请期待"></el-empty>
            </div>
            <div class="store-item"  v-show="false">
                <!--图片-->
                <div>
                    <img src="https://bushiroad.oss-cn-beijing.aliyuncs.com/home/zcTRRjMaZp0TovqD9Nvi9OQiHmi4lAOe1650610934.jpg">
                </div>
                <!--内容-->
                <div>
                    <div class="store-desc">
                        <div>科兴卡牌</div>
                        <div>
                            <img src="../../../assets/img/index-local.png">
                        </div>
                    </div>
                    <div class="store-desc">
                        <div>广东省深圳市南山区科兴科学园A1-11奈非天科技有限公司</div>
                        <div>
                            <img src="../../../assets/img/index-phone.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="store-easy" v-for="item in storeList" :key="item.id">
                <div style="font-weight: bold;font-size: 16px">{{item.name}}</div>
                <div>地址:{{item.address}}</div>
                <div>电话:{{item.phone}}</div>
            </div>
        </div>
        <div class="m-clear-both"></div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                areaOptions: this.$webConfig.areaData ,
                form:{
                    area:["110000", "110100"]
                },
                pageSize:this.$webConfig.pages.pageSize * 3,
                pageSizes:this.$webConfig.pages.pageSizes,
                currentPage:1,
                total:0,
                storeList:[],
            }
        },
        methods: {
            handleAreaChange(value){
                console.log(value);
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.currentPage = val;
                this.getList();
            },
            getList:function () {
                let page = this.currentPage;
                let limit = this.pageSize;
                let area = this.form.area;
                this.$axios.post("site/store/getList",{page,limit,area})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.storeList = res.data.data.list;
                            this.total = res.data.data.total;
                        }
                    })
            },
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>
    .content-main{
        background-color: white;
        padding: 20px 0;
        width: 100%;
    }
    .store-search{
        margin: 0 auto;
        width: 60%;
        text-align: center;
    }
    .store-list{
        width: 60%;
        margin: 0 auto;
        padding-bottom: 100px;
    }
    .store-item{
        float: left;
        height: 140px;
        background-color: #d6d6d6;
        width: 48%;
        margin: 1%;
    }
    .store-item >div{
        float: left;
        height: 110px;
    }
    .store-item >div:first-child{
        padding: 15px;
        width: 25%;
    }
    .store-item >div:first-child >img{
        height: 100%;
    }
    .store-item >div:last-child{
        width: 65%;
        height: 100%;
    }

    .store-desc >div{
        padding: 5px;
    }
    .store-desc >div:first-child{
        float: left;
        width: 75%;
        line-height: 20px;
        margin-top: 10px;
        font-size: 14px;
    }
    .store-desc >div:last-child{
        float: right;
        width: 15%;
    }
    .store-desc img{
        max-height: 30px;
        margin-top: 15px;
    }

    .store-easy{
        width:48%;
        float: left;
        margin: 1%;
        border-bottom: solid 1px #dcdfe6 ;
        color: #111111;
        font-family: 'Alibaba-PuHuiTi-Heavy';
        line-height: 25px;
        font-size: 14px;
        letter-spacing: 1px;
    }

</style>
